<template>
    <div class="view-list">
        <PButton
            v-if="hasContentItems && visibleRemoveAllButton && !disabled"
            variant="text-underline"
            color="primary"
            class="remove-all-button"
            @click.stop="clear"
        >
            <!-- @slot Заголовок для удаления всех пунктов -->
            <slot name="delete-title">
                {{ deleteText }}
            </slot>
            ({{ count }})
        </PButton>

        <template v-if="hasContentItems">
            <ul role="list" class="vlList" :class="{ '-isListView': isListView, '-disabled': disabled }">
                <li
                    v-for="(item, index) in filteredOptions"
                    :key="item.value as string"
                    class="vlItem list_item"
                    role="listitem"
                    :aria-label="item.label"
                    :class="{ '-disabled': item.disabled }"
                >
                    <!-- @slot Контейнер  для всего item блока -->
                    <slot name="item-container" :remove-item="() => removeItem(item)" :item="item" :index="index">
                        <!-- @slot Блок перед item контентом -->
                        <slot name="item-before" :index="index" :item="item"></slot>
                        <!-- @slot Блок контента item  -->
                        <slot name="item-content" :index="index" :item="item" :remove-item="() => removeItem(item)">
                            <PButton
                                variant="text"
                                color="navy-blue"
                                :icon="!disabled ? 'close-small' : undefined"
                                text-wrap
                                style="font-size: 14px"
                                :disabled="item.disabled"
                                @click.stop="removeItem(item)"
                            >
                                <!-- @slot Блок для custom значения текста для item  -->
                                <slot name="item" :index="index" :item="item"> {{ item.label }} </slot>
                            </PButton>
                        </slot>
                        <!-- @slot Блок после item контента  -->
                        <slot name="item-after" :index="index" :item="item"></slot>
                    </slot>
                </li>
            </ul>
        </template>

        <template v-else>
            <p class="text-gray-500 text-[12px] truncate" role="alert">{{ emptyText }}</p>
        </template>

        <div v-if="isLimit && hasContentItems" class="text-center">
            <PButton
                variant="text"
                color="primary"
                small
                :icon="isVisibleAllItems ? 'caret-up' : 'caret-down'"
                @click="toggleVisibleAllItems"
            >
                <template v-if="!isVisibleAllItems"> Показать еще ({{ hiddenItemsCount }}) </template>
                <template v-else> Свернуть </template>
            </PButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import get from 'lodash/get';
import { get as getValue } from 'lodash';
import PButton from '../PButton/PButton.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InternalItem<T = any> {
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    raw: T;
    disabled: boolean;
}

interface Props {
    /**
     * Модель данных передаваемый в компонент
     */
    modelValue: unknown[];

    options?: unknown[];

    /**
     * Ключ для поиска значения в объекте для подстановки в модель данных
     */
    valueKey?: string;

    /**
     * Ключ по которому выводится текст для item-пунктов
     */
    labelKey?: string;

    /**
     * Текст для отображения в блоке удаления всех пунктов
     */
    deleteText?: string;

    /**
     * Текст для отображения в блоке пустых данных
     */
    emptyText?: string;

    /**
     * Кол-во пунктов для отображения
     */
    limit?: number;

    /**
     * Отображение пунктов в одну строку
     */
    isListView?: boolean;

    /**
     * Показать/скрыть кнопку для удаления всех пунктов
     */
    visibleRemoveAllButton?: boolean;

    /**
     *  Параметр, который возвращает в modelValue данные как объект
     */
    returnObject?: boolean;

    /**
     *  Возможность блокировать удаление item
     */
    disabled?: boolean;

    /**
     *  Ключ по которому определяется параметр для блокировки item
     */
    disabledKey?: string;
}

const props = withDefaults(defineProps<Props>(), {
    valueKey: 'id',
    labelKey: 'name',
    deleteText: 'Удалить все пункты',
    emptyText: 'Нет данных',
    limit: 20,
    isListView: false,
    visibleRemoveAllButton: true,
    options: undefined,
    disabledKey: 'disabled'
});

const emit = defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:modelValue', payload: any[]): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'remove', payload: any): void;
}>();

const transformItem = (item: unknown): InternalItem => {
    const label = getValue(item, props.labelKey, String(item));
    const value = getValue(item, props.valueKey, item);
    const disabled = getValue(item, props.disabledKey) === true;

    return {
        label,
        value,
        raw: item,
        disabled
    };
};

const model = computed<InternalItem[]>({
    get() {
        if (props.modelValue === null || props.modelValue === undefined) {
            return [];
        }

        return props.modelValue.map(transformItem);
    },
    set(value) {
        const values = value.map(v => (props.returnObject ? v.raw : v.value));
        emit('update:modelValue', values);
    }
});

const isVisibleAllItems = ref(false);

const count = computed(() => {
    return model.value.length;
});

const isLimit = computed(() => {
    return count.value > props.limit;
});

const hiddenItemsCount = computed(() => {
    return props.modelValue.length - props.limit;
});

const hasContentItems = computed(() => {
    return filteredOptions.value.length > 0;
});

const computedOptions = computed(() => {
    const options = props.options ?? props.modelValue;
    return options.map(transformItem);
});

const filteredOptions = computed(() => {
    let options: InternalItem[] = computedOptions.value;

    if (props.options) {
        if (props.returnObject) {
            const keys = props.modelValue ? props.modelValue.map(item => get(item, props.valueKey, item)) : [];
            options = computedOptions.value.filter(option => keys.includes(option.value));
        } else {
            options = computedOptions.value.filter(option => props.modelValue?.includes(option.value));
        }
    }

    return isVisibleAllItems.value ? options : options?.slice(0, props.limit);
});

const removeItem = (data: InternalItem) => {
    if (props.disabled || data.disabled) {
        return;
    }
    model.value = model.value.filter(item => item.value !== data.value);
    emit('remove', data.raw);
};

const toggleVisibleAllItems = () => {
    isVisibleAllItems.value = !isVisibleAllItems.value;
};

const clear = () => {
    emit('update:modelValue', []);
};
</script>

<style lang="scss" scoped>
.view-list {
    background-color: var(--bg-color);
    border: 1px solid var(--border-light);
    border-radius: var(--border-radius-8);
    padding: 1.4rem 1.6rem;
    box-shadow: var(--shadow-card);

    .remove-all-button {
        margin-bottom: 1.6rem;
    }
}

.vlList {
    padding: 0;
    list-style: none;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1.2rem;
    font-size: var(--text-size-14);

    &.-isListView {
        flex-direction: column;
    }

    &.-isListView &_item {
        width: 100%;
    }
}

.vlItem {
    &_content {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
</style>
