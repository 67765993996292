import type { Brand, dateISO } from '@/shared/model/types/Kernel';
import type { CompanyId, CompanyProtekId } from '@/shared/model/types/Company';
import type { PlacementId } from '@/entities/Placement';
import type { CampaignTypes } from '@/entities/Campaign';
import type { Keyword } from '@/entities/Keywords';
import type { ZdravcityCategory } from '@/entities/ZdravcityCategory';
import type { ProductGuid } from '@/entities/ProductGuid/model/ProductGuid';
import type { PlacementDocument } from '@/entities/PlacementDocuments';
import type { MonthNamesType } from '@/utils';
import type { BookingPreset } from './BookingPreset';

export type BookingId = Brand<number, 'booking'>;
export type BookingBrandId = Brand<number, 'bookingBrand'>;

export enum BookingStatus {
    CREATED = 'CREATED',
    CONTENT_REQUEST = 'CONTENT_REQUEST',
    LAWYERS_REVIEW = 'LAWYERS_REVIEW',
    READY_FOR_START = 'READY_FOR_START',
    AD_WAS_STARTED = 'AD_WAS_STARTED',
    CANCELED = 'CANCELED',
    DELETED = 'DELETED'
}

export enum BookingAgreementType {
    ADVERTISING = 'ADVERTISING',
    INFO = 'INFO',
    CREDIT_NOTE = 'CREDIT_NOTE'
}

export enum BookingDocumentStatus {
    NEW = 'NEW',
    ON_THE_SIGNATURE = 'ON_THE_SIGNATURE',
    ARCHIVE = 'ARCHIVE',
    CANCELLED = 'CANCELLED',
    AGREEMENT = 'AGREEMENT',
    NOT_REQUIRED = 'NOT_REQUIRED',
    CREDIT_NOTE = 'CREDIT_NOTE'
}

export enum BookingOrdStatus {
    SELF_ADVERTISEMENT = 'SELF_ADVERTISEMENT',
    SUBMITTED_TO_ORD = 'SUBMITTED_TO_ORD',
    NO_ACT = 'NO_ACT',
    NO_MARKED = 'NO_MARKED'
}

export interface Booking {
    readonly id: BookingId;
    readonly name: string;
    actNumber: string | null;
    adAgreementType: BookingAgreementType | null;
    adLabel: string | null;
    advertisingInfo: string | null;
    agreementDate: dateISO | null;
    agreementNumber: string | null;
    readonly allAccountingDocumentsFilled: boolean;
    applicationNumber: string | null;
    banner: string | null;
    readonly brandId: BookingBrandId;
    brand: string;
    campaignId: CampaignTypes.CampaignId | null;
    cancelComment: string | null;
    cancelDate: dateISO;
    readonly cancelPrice: number | null;
    categories: ZdravcityCategory[];
    comment: string | null;
    commentForExternalPlacement: string | null;
    companyId: CompanyId;
    contentComment: string | null;
    contactEmail: string | null;
    contactName: string | null;
    contactPhone: string | null;
    documentComment: string | null;
    documentName: PlacementDocument | null;
    documentStatus: BookingDocumentStatus | null;
    startDate: dateISO;
    endDate: dateISO;
    erIdCustom: string | null;
    startDateInDocs: dateISO | null;
    endDateInDocs: dateISO | null;
    impression: number | null;
    monthCommit: MonthNamesType | null;
    monthPayment: MonthNamesType | null;
    isPrescription: boolean | null;
    juridicalName: string | null;
    keywords: Keyword[];
    readonly advertisingDiscount: number | null;
    readonly nds: number;
    readonly preset: BookingPreset | null;
    readonly price: number;
    priceWithNds: number | null;
    priceWithNdsOverridden: number | null;
    productGuids: ProductGuid[];
    productWabcCodes: ProductGuid[];
    placementId: PlacementId | null;
    ordStatus: BookingOrdStatus | null;
    screenshotDate: dateISO | null;
    screenshotSended: boolean;
    skuInDocs: string | null;
    status: BookingStatus;
    readonly prevStatus: BookingStatus | null;
    wabcCode: CompanyProtekId | null;
    readonly erId: string | null;
    readonly createdAt: dateISO;
    readonly updatedAt: dateISO;
}
