<template>
    <div class="space-y-5">
        <PIcon name="error-outline" size="50" style="color: var(--info)" />
        <h2>Хотите ли вы обновить среднее значение?</h2>
    </div>

    <div class="grid gap-5 grid-cols-2">
        <PButton variant="outline" @click="cancel"> Не обновлять </PButton>

        <PButton @click="confirmed"> Обновить </PButton>
    </div>
</template>

<script lang="ts" setup>
import { PButton, PIcon } from '@/shared/ui';

const emit = defineEmits<{
    (e: 'confirm'): void;
    (e: 'cancel'): void;
}>();

const confirmed = () => {
    emit('confirm');
};

const cancel = () => {
    emit('cancel');
};
</script>
