import { defineStore } from 'pinia';
import type { Segment, SegmentId } from './Segment';
import { getSegments } from '../api/Segment';
import type { Pagination } from '@/shared/model/types/Pagination';
import { AVAILABLE_SEGMENT_IDS } from '@/entities/Segment/model/constants';

export interface SegmentStore {
    segments: Record<SegmentId, Segment>;
    cached: boolean;
}

export const useSegmentStore = defineStore('Segment', {
    state: () =>
        ({
            segments: {},
            cached: false
        }) as SegmentStore,

    getters: {
        segmentsArray: state => Object.values(state.segments),
        segmentsFiltered(): Segment[] {
            return this.segmentsArray.filter(({ id }) => {
                return AVAILABLE_SEGMENT_IDS.includes(id);
            });
        }
    },

    actions: {
        async fetchSegments(
            ...params: Parameters<typeof getSegments>
        ): Promise<{ data: Segment[]; pagination: Pagination }> {
            const response = await getSegments(...params);
            for (const segment of response.data) {
                this.segments[segment.id] = segment;
            }
            return response;
        },
        async fetchCached(): Promise<Segment[]> {
            if (!this.cached) {
                await this.fetchSegments();
                this.cached = true;
            }

            return this.segmentsArray;
        },
        async fetchByIdsCached(ids: SegmentId[]): Promise<Segment[]> {
            const oldIds = Object.keys(this.segments).map(id => Number(id));
            const newIds = ids.filter(id => !oldIds.includes(id));
            if (newIds.length) {
                await this.fetchSegments({
                    params: {
                        ids: newIds
                    },
                    pagination: { currentPage: 1, itemsPerPage: newIds.length }
                });
            }
            return this.segmentsArray.filter((s: Segment) => ids.includes(s.id));
        }
    }
});
