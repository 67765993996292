import client from '@/shared/api/client';
import { generateUUID } from '@/utils';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { B2BAsyncReport, TypeOrder } from '../model/B2BAsyncReport';
import type { CompanyProtekId } from '@/shared/model/types/Company';
import { formatReportFileName } from '../model/utils';
import { ReportActionAsyncType } from '@/entities/ActionPromo';

interface GetAsyncReportsPayload {
    supplierId: CompanyProtekId | null;
    typeOrder?: TypeOrder[];
}

export const getAsyncReports = async (
    taskType: ReportActionAsyncType | 'adGoods',
    payload: GetAsyncReportsPayload & ServerPagination,
    abortController = new AbortController()
): Promise<{
    tasks: B2BAsyncReport[];
    pagination: Pagination;
}> => {
    const body = {
        jsonrpc: '2.0',
        method: 'b2bAsyncReportsTasks.getList',
        id: generateUUID(),
        params: {
            page: payload.currentPage ?? 1,
            limit: payload.itemsPerPage ?? 10,
            status: '',
            taskType,
            wabcCode: payload.supplierId,
            typeOrder: payload.typeOrder
        }
    };

    const response = await client.post('/rpc', body, {
        signal: abortController.signal
    });

    return {
        tasks: response.data.result.tasks,
        pagination: {
            currentPage: payload.currentPage,
            itemsPerPage: payload.itemsPerPage,
            totalItems: response.data.result.total_count
        }
    };
};

export const getAsyncRecentReports = async (
    taskType: string,
    payload: GetAsyncReportsPayload,
    abortController = new AbortController()
): Promise<B2BAsyncReport[]> => {
    const body = {
        jsonrpc: '2.0',
        method: 'b2bAsyncReportsTasks.getRecentList',
        id: generateUUID(),
        params: {
            status: '',
            taskType,
            wabcCode: payload.supplierId,
            typeOrder: payload.typeOrder
        }
    };

    const { data } = await client.post<{ result: B2BAsyncReport[] }>('/rpc', body, {
        signal: abortController.signal
    });
    return data.result;
};

export async function deleteAsyncReportById(taskId: string) {
    const payload = {
        jsonrpc: '2.0',
        method: 'b2bAsyncReportsTasks.delete',
        id: generateUUID(),
        params: {
            taskId
        }
    };

    const { data } = await client.post('/rpc', payload);
    return data;
}

export interface CreateAsyncReportsPayload {
    startDate: string;
    endDate: string;
    fileName: string;
    taskType: string;
    type: string;
    fields: { tf: string; f: string }[];
    reportName: string;
    parameters: Record<string, unknown>;
}
export async function createAsyncReport(payload: CreateAsyncReportsPayload) {
    const fileName = formatReportFileName(payload);

    const body = {
        id: generateUUID(),
        jsonrpc: '2.0',
        method: 'b2bAsyncReportsTasks.create',
        params: {
            taskType: payload.taskType,
            exportFormat: {
                type: payload.type,
                encoding: payload.type === 'csv' ? 'windows-1251' : 'CP866',
                fields: payload.fields,
                fieldSeparator: ';',
                decimalSeparator: ',',
                quoteChar: '"',
                resultFileName: `${fileName}.${payload.type}`,
                archiveFileName: `${fileName}-${payload.type}.zip`
            },
            parameters: payload.parameters
        }
    };

    const { data } = await client.post('/rpc', body);
    return data.result;
}
