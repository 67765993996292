<template>
    <PSelect
        v-model="ordStatus"
        :label="label"
        :clearable="clearable"
        :options="ordStatusOptions"
        :multiple="multiple"
        :disabled="disabled"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PSelect } from '@/shared/ui';
import { BookingOrdStatus } from '../model/Booking';
import { getBookingOrdStatusName } from '../model/utils';

withDefaults(
    defineProps<{
        disabled?: boolean;
        label?: string;
        multiple?: boolean;
        clearable?: boolean;
    }>(),
    {
        label: 'Подано в ОРД'
    }
);

const ordStatus = defineModel('modelValue');

const ordStatusOptions = computed(() => {
    return Object.values(BookingOrdStatus).map(status => ({
        id: status,
        name: getBookingOrdStatusName(status)
    }));
});
</script>
