import client from '@/shared/api/client';
import { type PlacementTypes } from '@/entities/Placement';
import type { Pagination } from '@/shared/model/types/Pagination';
import type { StatisticsKeyword } from '../model/StatisticsKeyword';
import { omit } from 'lodash';

interface StatisticsKeywordsPayload {
    keywordName: string;
    year: string;
    placementId: PlacementTypes.PlacementId;
    pagination: Pagination;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toClient = (keyword: any): StatisticsKeyword => {
    return {
        ...keyword,
        averageViewsOverriden: keyword.averageViewsOverriden ?? keyword.averageViews
    };
};

const toServer = (keyword: Partial<StatisticsKeyword>) => {
    return omit(keyword, ['average', 'averageViews']);
};

export const getStatisticsKeywords = async (payload: StatisticsKeywordsPayload) => {
    const { data } = await client.get<{ data: StatisticsKeyword[]; meta: Pagination }>('/api/v1/keywords/statistics', {
        params: {
            name: payload.keywordName,
            placementId: payload.placementId,
            year: payload.year,
            limit: payload.pagination.itemsPerPage,
            page: payload.pagination.currentPage
        }
    });

    return {
        data: data.data.map(toClient),
        pagination: data.meta
    };
};

export const getStatisticsKeywordById = async (statisticsKeywordId: number) => {
    const { data } = await client.get<StatisticsKeyword>(`/api/v1/keywords/statistics/${statisticsKeywordId}`);
    return toClient(data);
};

export const createStatisticsKeyword = async (payload: Omit<StatisticsKeyword, 'id'>) => {
    const { data } = await client.post<StatisticsKeyword>('/api/v1/keywords/statistics/', toServer(payload));
    return toClient(data);
};

export const updateStatisticsKeywordById = async (
    keywordStatisticsId: number,
    payload: Omit<StatisticsKeyword, 'id' | 'averageViews' | 'averageViewsOverriden'>
) => {
    const { data } = await client.patch<StatisticsKeyword>(
        `/api/v1/keywords/statistics/${keywordStatisticsId}`,
        payload
    );
    return toClient(data);
};

export const deleteStatisticsKeywordById = async (statisticsKeywordId: number) => {
    return await client.delete(`/api/v1/keywords/statistics/${statisticsKeywordId}`);
};
