import client from '@/shared/api/client';
import type { Segment, SegmentId } from '../model/Segment';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import { generateUUID } from '@/utils';
import { dayjs } from '@/shared/lib/dayjs/dayjs';

interface SegmentParams {
    ids?: SegmentId[];
    names?: string[];
    fullNames?: string[];
}
export const getSegments = async (
    payload: {
        params?: SegmentParams;
        pagination?: ServerPagination;
    } = {}
): Promise<{ data: Segment[]; pagination: Pagination }> => {
    const DEFAULT_PAGINATION = { currentPage: 1, itemsPerPage: 100 };
    const pagination = payload.pagination || DEFAULT_PAGINATION;
    const params = payload.params || {};

    const serverPayload = {
        segment_id: Array.isArray(params.ids) ? params.ids : [],
        segment_name: Array.isArray(params.names) ? params.names : [],
        full_segment_name: Array.isArray(params.fullNames) ? params.fullNames : [],
        start_from: (pagination.currentPage - 1) * pagination.itemsPerPage,
        count: pagination.itemsPerPage,
        correlation_id: generateUUID()
    };

    const response = await client.post('/api/proxy/directory/dct-segment', serverPayload);
    const data = Array.isArray(response.data?.data?.dctSegment) ? response.data.data.dctSegment : [];
    const totalItems: number = response.data.total_record_count;
    const clientPagination: Pagination = {
        totalItems,
        currentPageItemsCount: data.length,
        itemsPerPage: pagination.itemsPerPage,
        totalPages: Math.ceil(totalItems / pagination.itemsPerPage),
        currentPage: pagination.currentPage
    };

    return {
        pagination: clientPagination,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: data.map((item: any) => ({
            id: item.segmentId,
            name: item.segmentName,
            fullName: item.segmentFullName,
            lastOrderDate: dayjs(item.lastOrderDate, 'DD.MM.YYYY').toISOString()
        }))
    };
};
