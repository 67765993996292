<template>
    <PSelect
        v-model="value"
        :label="label"
        :label-key="labelKey"
        :value-key="valueKey"
        :clearable="clearable"
        :options="placements"
        :disabled="disabled"
        :multiple="multiple"
        @clear="clear"
        @add-item="update"
        @remove-item="update"
    />
</template>

<script lang="ts" setup generic="T extends Placement | number">
import { computed, onMounted } from 'vue';
import { PSelect } from '@/shared/ui';
import type { Placement } from '../model/Placement';
import { usePlacementStore } from '../model/PlacementStore';

interface Props {
    modelValue?: T | T[] | null;
    label?: string;
    disabled?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    labelKey?: string;
    valueKey?: string;
    filterOptions?: (placement: Placement) => boolean;
    // TODO: заменить на функцию фильтрации опций снаружи
    // https://jira.zdravcity.rocks/browse/CAB-2528
    filterByPermissions?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    label: 'Выберите размещение',
    clearable: true,
    labelKey: 'name',
    valueKey: 'adId',
    filterByPermissions: true,
    multiple: false,
    filterOptions: undefined
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: Props['modelValue']): void;
    (e: 'select', value: Placement | undefined): void;
}>();

const placementStore = usePlacementStore();
const value = defineModel<Props['modelValue']>('modelValue');

const update = (placement?: Placement) => {
    emit('select', placement);
};

const placements = computed(() => {
    if (props.filterOptions) {
        return placementStore.placements.filter(props.filterOptions);
    }

    return props.filterByPermissions ? placementStore.placementsArrayForUser : placementStore.placements;
});

const clear = () => {
    emit('select', undefined);
};

onMounted(async () => {
    await placementStore.fetchPlacementsCached();
});
</script>
