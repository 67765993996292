import { computed, ref, toValue, watch, type MaybeRefOrGetter } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import { type B2BAsyncReport, B2BAsyncReportStatus } from '../model/B2BAsyncReport';

const REFRESH_DELAY = 1_500;
const POOLING_TIMEOUT = 10_000;

export const useRefreshTasksStatus = (tasks: MaybeRefOrGetter<B2BAsyncReport[]>, fetchData: () => Promise<void>) => {
    const refreshStatus = ref(false);

    const isRefreshingTasks = computed(() => {
        return toValue(tasks).some(task =>
            [B2BAsyncReportStatus.IN_PROGRESS, B2BAsyncReportStatus.ACCEPTED].includes(task.status)
        );
    });

    watch(isRefreshingTasks, isRefreshing => {
        isRefreshing ? intervalInstance.resume() : intervalInstance.pause();
    });

    const intervalInstance = useIntervalFn(
        () => {
            refreshStatus.value = true;
            fetchData().finally(() => {
                setTimeout(() => {
                    refreshStatus.value = false;
                }, REFRESH_DELAY);
            });
        },
        POOLING_TIMEOUT,
        { immediate: false }
    );

    return refreshStatus;
};
