import { ACTION_PROMO_TYPE_ACTION, ACTION_REPORT_ACTION_TYPE } from './constants';
import {
    ActionPromoBonusPackageSubTypeAction,
    type ActionPromoColumnNameKey,
    type ActionPromoTable,
    ActionPromoTypeAction,
    ReportActionAsyncType,
    ReportActionType
} from './types';

export const getActionPromoTypeName = (actionDetail: ActionPromoTable) => {
    const isByRuble = actionDetail.actionSubtype === ActionPromoBonusPackageSubTypeAction.BY_RUBLE;
    const isBonusPackage = actionDetail.actionType === ActionPromoTypeAction.BONUS_PACKAGE;

    if (isBonusPackage && isByRuble) {
        return ACTION_PROMO_TYPE_ACTION[actionDetail.actionType] + ' за сумму заказа';
    } else {
        return ACTION_PROMO_TYPE_ACTION[actionDetail.actionType];
    }
};

export const getActionReportTypeName = (reportActionType: ReportActionType) => {
    return ACTION_REPORT_ACTION_TYPE[reportActionType];
};

export const getActionAsyncReportTypeName = (type: ReportActionAsyncType) => {
    const names: Record<ReportActionAsyncType, string> = {
        [ReportActionAsyncType.SALE_IN_PRICE]: 'Скидка в цене',
        [ReportActionAsyncType.BONUS_PACKAGE]: 'Бонусная упаковка N+M',
        [ReportActionAsyncType.BONUS_PACKAGE_BY_RUBLE]: 'Бонусная упаковка за сумму заказа',
        [ReportActionAsyncType.ZDRAVCITY]: 'Здравсити'
    };

    return names[type] ?? type;
};

export const getActionReportAsyncType = (reportActionType: ReportActionType): ReportActionAsyncType => {
    switch (reportActionType) {
        case ReportActionType.SALE_IN_PRICE:
            return ReportActionAsyncType.SALE_IN_PRICE;
        case ReportActionType.BONUS_PACKAGE:
            return ReportActionAsyncType.BONUS_PACKAGE;
        case ReportActionType.BONUS_PACKAGE_BY_RUBLE:
            return ReportActionAsyncType.BONUS_PACKAGE_BY_RUBLE;
    }
};

export const getActionPromoColumnNameByKey = (columnNameKey: ActionPromoColumnNameKey) => {
    const actionPromoColumnNameMap: Record<ActionPromoColumnNameKey, string> = {
        actionName: 'Название акции',
        asName: 'Наименование сети',
        billCdU: 'Код плательщика',
        billFactAddress: 'Фактический адрес плательщика',
        billInn: 'ИНН плательщика',
        billJurAddress: 'Юридический адрес плательщика',
        billName: 'Наименование плательщика',
        bonus: 'Бонус',
        bonusDocDate: 'Дата акцепта бесплатной накладной',
        bonusDocNum: 'Бесплатная накладная',
        bonusMetrics: 'Мера бонуса',
        bonusNachisl: 'Начисление бонуса',
        bonusName: 'Наименование бесплатного препарата',
        bonusProvider1p: 'Бонус за 1 пакет, руб',
        bonusProvider: 'Бонус от поставщика, руб',
        bonusRealized: 'Выданный бонус',
        cdU: 'Код получателя',
        compensation: 'Компенсация бонусной упаковки, руб',
        compensationCdU: 'Итоговая компенсация поставщику, руб',
        contractPrice: 'Контрактная цена, руб.',
        dateAccept: 'Дата акцепта',
        dateOrd: 'Дата заказа',
        dateOrdPay: 'Дата заказа платной накладной',
        dateYear: 'Год',
        deptName: 'Сбытовое подразделение',
        divided: 'Количество пакетов',
        docDate: 'Дата акцепта платной накладной',
        docNum: 'Накладная',
        docsNumFree: 'Бесп. накладная',
        docsNumPay: 'Плат. Накладная',
        dolg: 'Долг',
        factSum: 'Фактическая отгрузка',
        filCdu: 'Код филиала',
        fpriceWNds: 'Конкурсная отгрузка  (руб с НДС)',
        fpriceWoNds: 'Конкурсная отгрузка  (руб без НДС)',
        groupCond: '№гр /№усл.',
        inn: 'ИНН получателя',
        medAmn: 'Кратность, уп',
        medicineCode: 'Код препарата',
        medicineName: 'Наименование препарата',
        medsBonus: 'Код бесплатного препарата',
        medsPlan: 'Кратные уходы (упак)',
        nmActions: 'ИД акции',
        nmAgent: 'Менеджер',
        nmCrossActions: 'Код набора условий',
        nmU: 'Наименование получателя',
        planBegin: 'План «С»',
        planEnd: 'План «До»',
        remark: 'Примечание',
        saleManagment: 'РУ',
        segmentId: 'Код сегмента',
        segmentName: 'Наименование сегмента',
        shipfactAddress: 'Фактический адрес получателя',
        shipJurAddress: 'Юридический адрес получателя',
        souzfarmaFlag: 'Признак ассоциации Союзфарма',
        medReturn: 'Возврат, уп.',
        supplierCode: 'Код поставщика',
        operTp: 'Тип операции',
        actionZdravcityId: 'ИД акции',
        orderedDate: 'Дата заказа',
        acceptDate: 'Дата акцепта заказа',
        regName: 'Регион',
        supplierName: 'Наименование поставщика',
        paySiteUseId: 'Код отделения клиента-плательщика',
        payPartnerCode: 'Код клиента-плательщика',
        payInn: 'ИНН клиента-плательщика',
        payPartnerName: 'Наименование клиента-плательщика',
        payFactAddress: 'Фактический адрес клиента-плательщика',
        payJuridAddress: 'Юридический адрес клиента-плательщика',
        recSiteUseId: 'Код отделения клиента-получателя',
        recPartnerCode: 'Код клиента-получателя',
        recInn: 'ИНН клиента-получателя',
        recPartnerName: 'Наименование клиента-получателя',
        recFactAddress: 'Фактический адрес клиента-получателя',
        recJuridAddress: 'Юридический адрес клиента-получателя',
        actionZdravcityName: 'Название акции',
        actionZdravcityType: 'Тип акции',
        producerName: 'Производитель',
        orderNumber: 'Номер заказа',
        trxNumber: 'Номер товарной накладной',
        operTpName: 'Наименование операции',
        amnZakaz: 'amnZakaz',
        cvpDiscountRub: 'Фактически начисленные скидки клиенту без НДС, руб',
        imaDiscountRub: 'Фактически начисленные скидки клиенту c НДС, руб',
        imaRub: 'Стоимость проданных упаковок в Товарной Накладной (до скидки), руб',
        bonusSaleRub: 'Сумма нормативного списания бонусов по реализованному товару',
        medShip: 'Отгрузка, уп',
        debtShipRub: 'Отгрузка с НДС, руб',
        shipRub: 'Отгрузка без НДС, руб',
        primeShipRub: 'Себестоимость, руб',
        debtReturnRub: 'Возврат с НДС, руб',
        returnRub: 'Возврат себестоимость, руб',
        primeReturnRub: '',
        sumRub: 'Стоимость проданных упаковок в Товарной Накладной с учетом скидки, руб',
        sumDebtRub: 'Стоимость проданных упаковок в Товарной Накладной с учетом скидки с НДС, руб',
        medNoAccept: 'Неакцепт, уп'
    };

    return actionPromoColumnNameMap[columnNameKey] ?? columnNameKey;
};
